import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.reducer';
import { isCustomOrTrader } from './store/project/project.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {

  title = 'trade-front-admin';
  template = '';
  sub: Subscription;

  constructor (
    private store: Store<AppState>
  ) {
    this.sub = this.store.select(isCustomOrTrader).subscribe(c => {
      if (c) {
        this.template = "<img src='assets/loader/loader-licenciamiento.gif' width='150' />";
      } else {
        this.template = "<img src='assets/loader/loader-trader.gif' width='150' />";
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
