<div class="d-flex flex-column w-100 h-100">
	<h1 mat-dialog-title class="px-4 pt-4 m-0 h-title">Traders Aceptados</h1>
	<div class="d-flex flex-column px-4 content">
		<div class="my-2">
			<mat-icon class="icon-search d-flex justify-content-center align-items-center">search</mat-icon>
			<input #inputSearch type="text" class="w-100 form-control form-control-custom input-search outline-no" placeholder="Ingrese el nombre o el ID del trader" (input)="filterData($event.target)">
		</div>
		<div class="w-100 mt-2" style="overflow-y:auto; height: 400px;">
			<table mat-table [dataSource]="dataSource.filteredData | slice:0:50" class="custom-table">
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef class="text-center"> ID TRADER </th>
					<td mat-cell *matCellDef="let element" class="text-center"> {{element.fcUser}} </td>
				</ng-container>
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef> NOMBRE TRADER </th>
					<td mat-cell *matCellDef="let element"> {{element.fcName}} </td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell text-center" colspan="2"></td>
				</tr>
			</table>
		</div>
	</div>
	<div mat-dialog-actions align="end" class="end-bottons px-4 py-3 mx-4 my-2">
		<button mat-stroked-button color="accent" (click)="close.emit()" style="width: 140px;height: 50px;background-color: #FFF;">Salir</button>
	</div>
</div>
