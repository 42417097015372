export interface Modal {
  title: string;
  text: string;
  cancelButton?: boolean;
  confirmButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  justTitle?: boolean;
  type: TypeModalEnum;
  imgUrl?: string;
  isCustom?: boolean;
  confirmButtonLink?: boolean;
}

export enum TypeModalEnum {
  SUCCESS,
  ERROR,
  WARNING,
  CUSTOM,
  INFO,
  QUESTION
}
