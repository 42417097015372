import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Modal, TypeModalEnum } from '../../models/modals/info-modal-v2.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { Subscription } from 'rxjs';
import { isCustomOrTrader } from 'src/app/store/project/project.selectors';

@Component({
  selector: 'app-info-modal-v2',
  templateUrl: './info-modal-v2.component.html',
  styleUrls: ['./info-modal-v2.component.css']
})
export class InfoModalV2Component implements OnInit, OnDestroy {

  @Output() response: EventEmitter<any> = new EventEmitter<any>();
  @Input() data: Modal;
  isCustom: boolean = false;
  stopSub: Subscription;
  image: string;

  constructor(
    private modalservice:NgbActiveModal,
    private store: Store<AppState>
  ) { }

  ngOnDestroy(): void {
    this.stopSub.unsubscribe();
  }

  ngOnInit(): void {
    this.stopSub = this.store.select(isCustomOrTrader).subscribe(value => this.isCustom = value);
    switch (this.data.type) {
      case TypeModalEnum.SUCCESS:
        this.image = 'assets/modal/success.png';
        break;
      case TypeModalEnum.WARNING:
        this.image = 'assets/modal/warning.png';
        break;
      case TypeModalEnum.ERROR:
        this.image = 'assets/modal/error.png';
        break;
      case TypeModalEnum.CUSTOM:
        this.image = this.data.imgUrl || '';
        break;
      case TypeModalEnum.QUESTION:
        if (this.isCustom) {
          this.image = 'assets/modal/question-project.png';
        } else {
          this.image = 'assets/icons/question.png';
        }
        console.log(this.isCustom, this.image)
        break;
    
      default:
        this.image = 'assets/modal/success.png';
        break;
    }
  }

  closeModal(value: boolean){
    this.response.emit({action: value});
  }


}
