export const DirectionTooltip: Record<keyof DirectionTooltipType, string> = {
  TOPLEFT: 'top-left',
  BOTTOMLEFT: 'bottom-left',
  BOTTOMRIGHT: 'bottom-right'
}

export type DirectionTooltipType = {
  TOPLEFT: string;
  BOTTOMLEFT: string;
  BOTTOMRIGHT: string;
}