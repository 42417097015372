<!-- <ng-container>
	<div class="file-modal-header">
		<div class="row">
			<div class="col-12 text-center">
				<div class="fw-bold mt-4">{{titleModal}}</div>
			</div>
		</div>
	</div>
	<div class="modal-body p-4">
		<div class="row">
			<div class="col-12 text-center mt-5">
				<img src="assets/files-icon/excel.svg" class="img-excel">
				<div class="file-name mt-3 mb-3">{{fileName}}</div>
				<span class="other-file" (click)="startOther()">Subir otro archivo</span>
			</div>
		</div>
		<hr>
		<div class="row mt-4 mb-5 justify-content-center">
			<div class="col-4 text-center">
				<span class="info-users">{{ textAceppted}}</span><br>
				<span class="fw-bold">{{acceptedUsers.length}}</span>
			</div>
			<div class="col-4 text-center">
				<span class="info-users">{{ textDenied}}</span><br>
				<span class="fw-bold">{{deniedUsers.length}}</span>
			</div>
			<div class="col-4 text-center" *ngIf="deniedUsers.length > 0 || avanceVentas">
				<button mat-stroked-button color="primary" class="border-r btn-nooutline btn-down" (click)="startError()">
					Descargar
					<mat-icon class="icon-down">file_download</mat-icon>
				</button>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<div *ngIf="deniedUsers.length === 0 || avanceVentas">
			<button mat-raised-button color="primary" class="text-orange border-r bg-transp" (click)="startClose()"> Cancelar </button>
			<button mat-raised-button color="primary" class="btn-w border-r ms-2" (click)="startAction()" [disabled]="invalidAction"> {{ titleButton }} </button>
		</div>
		<div *ngIf="deniedUsers.length > 0 && !avanceVentas">
			<button mat-raised-button color="primary" class="btn-w border-r ms-2" (click)="startClose()"> Cancelar </button>
		</div>
	</div>
</ng-container> -->

<ng-container>
	<div class="d-flex flex-column align-items-center p-4 mt-3">
		<img src="assets/files-icon/excel.svg" class="img-excel">
		<span class="d-block filename">{{fileName}}</span>
		<span class="other-file" (click)="startOther()">Subir otro archivo</span>
		<h2 class="mt-3">{{titleModal}}</h2>
		<div class="d-flex align-items-center justify-content-center gap-3 py-2 px-3" style="box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);">
			<span class="count-user"><strong>{{acceptedUsers.length}}</strong> Aceptados</span>
			<span class="count-user"><strong>{{deniedUsers.length}}</strong> Rechazados</span>
			<button class="download-error" (click)="startError()">
				<mat-icon>arrow_downward</mat-icon>
				<span>
					Descargar
				</span>
			</button>
		</div>
	</div>
	<div class="modal-footer justify-content-evenly pt-4 pb-5 border-0">
		<ng-container *ngIf="deniedUsers.length === 0 || avanceVentas">
			<button mat-stroked-button color="accent" (click)="startClose()" style="width: 150px;"> Cancelar </button>
			<button mat-flat-button color="primary" class="ms-2" (click)="startAction()" [disabled]="invalidAction" style="width: 150px;"> {{ titleButton }} </button>
		</ng-container>
		<ng-container *ngIf="deniedUsers.length > 0 && !avanceVentas">
			<button mat-raised-button color="primary" class="ms-2" (click)="startClose()" style="width: 150px;"> Cancelar </button>
		</ng-container>
	</div>

</ng-container>