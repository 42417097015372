<div class="modal-header mb-4">
  <div class="question">
    <img [src]="image ?? 'assets/icons/question.png'" alt="Pregunta" width="100" height="100">
  </div>
</div>
<div class="modal-body pb-0" *ngIf="!data.justTitle">
	<h1 class="modal-title">{{ data.title }}</h1>
  <p class="modal-text" [innerHTML]="data.text"></p>
</div>
<!-- <div class="modal-body" *ngIf="data.justTitle">
	<h2 class="modal-title" [ngStyle]="{'font-size': data.titleSize}" [innerHTML]="data.title"></h2>
</div> -->
<div class="modal-footer" [ngClass]="{'justify-content-center': !data.cancelButton, 'justify-content-around': data.cancelButton}">
  <button *ngIf="data.cancelButton" mat-stroked-button color="accent" style="width: 150px;" (click)="closeModal(false)"> {{data.cancelButtonText}} </button>
  <button *ngIf="data.confirmButton && !data.confirmButtonLink" mat-flat-button color="primary" style="width: 150px;" (click)="closeModal(true)"> {{data.confirmButtonText}} </button>
  <button *ngIf="data.confirmButton && data.confirmButtonLink" mat-button color="accent" class="text-decoration-underline" style="width: 150px;" (click)="closeModal(true)"> {{data.confirmButtonText}} </button>
</div>
